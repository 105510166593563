.navBar {
  position: sticky;
  top: 0;
  z-index: 999;
  max-width: 100%;
  padding: 10px;

  padding: 10px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
}

.list {
  width: 200px;
}
.panel-list {
  position: fixed;
  padding: 10px;
  width: 200px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
}
#cosmo-logo {
  object-fit: contain;
  height: 20px;
  margin-left: 10px;
}
.listItem {
  display: flex;
  align-items: center;
}

.listItem > p {
  margin-left: 10px;
}

.listItem > i {
  font-size: 18px;
}

.panel-list .dx-list-item {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

#content:not(.fileManager) {
  margin: 10px;
  padding: 10px;
}

.dx-drawer-left .dx-drawer-panel-content:not(.dx-filemanager-dirs-panel) {
  width: 200px;
}

.card-title {
  font-size: 16px;
  font-weight: bold;
}

.card-sub-title {
  font-style: italic;
  color: grey;
  font-size: 13px;
}

.card-padding {
  padding: 10px;
}

.card-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.border-line {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px;
}

.app-version {
  font-style: italic;
  color: grey;
  font-size: 13px;
}

.toolbar-username {
  display: flex;
  align-items: center;
}
.toolbar-username > i {
  margin-right: 2px;
}

.navBar .dx-menu-item-content {
  padding: 5x !important;
}

.dx-toolbar-item {
  padding: 0px !important;
}

.navBar .dx-toolbar-after .dx-button-content {
  padding: 5px !important;
}

.navBar .dx-toolbar-after .dx-menu-item-content {
  padding: 5px !important;
}

.navBar .dx-icon-spindown {
  display: none;
}
