.ic-menu:before {
  content: "menu";
}

.ic-home:before {
  content: "home";
}

.ic-add:before {
  content: "add";
}
.ic-export:before {
  content: "import_export";
}
.ic-delete:before {
  content: "delete";
}
.ic-phone:before {
  content: "phone";
}

.ic-folder:before {
  content: "folder";
}

.ic-people:before {
  content: "people";
}

.ic-build:before {
  content: "build";
}

.ic-settings:before {
  content: "settings";
}

.ic-help:before {
  content: "help_outline";
}

.ic-user:before {
  content: "account_circle";
}

.ic-edit:before {
  content: "edit";
}

.ic-upload:before {
  content: "file_upload";
}

.ic-download:before {
  content: "download";
}

.ic-logout:before {
  content: "logout";
}

.ic-import:before {
  content: "upload_file";
}

.ic-column:before {
  content: "view_column";
}

.material-icons-outlined {
  font-size: 18px !important;
}

.navBar .dx-toolbar-after {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navBar .dx-toolbar-after .dx-item-content {
  display: flex;
  align-items: center;
}
