.login {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.login > div {
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 400px;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.login > div > img {
  object-fit: contain;
  width: 100% !important;
}
